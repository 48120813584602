<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="search"
			:option="search_option"

			@change="getData"
			@click="getData"
			@toExcel="toExcel"
		>
			<select
				v-if="false"
				v-model="search.admin_code"
				slot="add"
				class="pa-5-10 mr-10"
				@change="getData"
			>
				<option value="">소속 대리점</option>
				<option
					v-for="(agency, index) in agency_list"
					:key="'agency_' + index"
					:value="agency.account_id"
				>{{ agency.agency_name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 pa-10 bg-white full-height"
		>
			<div
				v-if="item_list.length > 0"
			>
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								type="checkbox"
							/>
						</th>
						<th v-if="false">소속 대리점</th>
						<th>아이디</th>
						<th>IP</th>
						<th>이름</th>
						<th>구분</th>
						<th>결제 금액</th>
						<th>충전 포인트</th>
						<th>결제일시</th>
						<th colspan="2">결제 상태</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'item_' + index"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td>
							<input
								type="checkbox"
							/>
						</td>
						<td v-if="false">{{ item.agency_name }}</td>
						<td>{{ item.member_id }}</td>
						<td>{{ item.member_ip }}</td>
						<td>{{ item.member_name }}</td>
						<td>{{ item.pay_div_name }}</td>
						<td>{{ item.order_price | makeComma}}원</td>
						<td>{{ item.order_point | makeComma }}P</td>
						<td>{{ item.wDate}} </td>
						<td
							:class="'color-' + item.o_status_color"
						>
							{{ item.o_status_name }}
						</td>
						<td>
							<button
								v-if="item.is_cancel"
								class="pa-5-10 bg-red"
								@click="onCancel(item)"
							>결제 취소<v-icon small class="color-eee">mdi mdi-chevron-right</v-icon></button>
							<template
								v-else-if="item.o_status == 4"
							>{{ item.mDate }}</template>
						</td>
					</tr>
					</tbody>
				</table>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"
				></Pagination>
			</div>
			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@close="is_excel = false"
		></Excel>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Excel from "@/components/Excel";
import Search from "@/view/Layout/Search";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'PointHistory'
	, components: {Empty, Search, Excel, Pagination}
	, props: ['user', 'codes']
	,data: function (){
		return {
			program: {
				name: '포인트 결제 목록'
				,top: true
				,title: true
			}
			,search: this.$storage.init({
				search_type: 'member_name'
				, member_id: ''
				, p_status: ''
				, list_cnt: 10
				, page: 1
				, pay_div: ''
				, sDate: this.$date.getMonthlyDate('', '-').start
				, eDate: this.$date.getToday('-')
				, o_status: ''
			})
			,search_option:{
				is_excel: true
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,sDate: true
				,eDate: true
				,search_type: [
					{ name: '아이디', column: 'member_id'}
					,{ name: '이름', column: 'member_name'}
				]
				,select: [
					{ name: '구분', column: 'pay_div', items: this.$codes.point_pay_div }
					, { name: '결제 상태', column: 'o_status', items: this.$codes.o_status }
				]
			}
			,items: []
			,is_excel: false
			,excel_data: {
				name: '포인트 결제 목록'
				,header: [
					{ key: 0, name: '아이디', column: 'member_id'}
					,{ key: 0, name: '이름', column: 'member_name'}
					,{ key: 0, name: '구분', column: 'pay_div_name'}
					,{ key: 0, name: '결제 금액', column: 'order_price'}
					,{ key: 0, name: '충전 포인트', column: 'order_point'}
					,{ key: 0, name: '결제 일시', column: 'wDate'}
					,{ key: 0, name: '결제 상태', column: 'o_status_name'}
					,{ key: 0, name: '취소 일시', column: 'is_mDate'}
				]
				,content: null
			}
			, item_new: {}
		}
	}
	,computed: {
		item_list: function (){
			let now = this.$date.getToday('-')
			return this.items.filter( (item, index) => {
				item.no = this.search.tCnt - (this.search.page * this.search.list_cnt - this.search.list_cnt) - index

				switch (item.o_status){
					default:
						item.o_status_color = "gray"
						break;
					case "1":
						item.o_status_color = "red"
						break;

					case "2":
						item.o_status_color = "green"
						if(now == item.wDate.substring(0, 10)){
							item.is_cancel = true
						}
						break
					case "3":
						item.o_status_color = "orange"
						item.is_cancel = false
						break
					case "4":
						item.o_status_color = "red"
						item.is_cancel = false
						item.is_mDate = item.mDate
						break;
				}

				this.$codes.point_pay_div.filter( (code) => {
					if(code.code == item.pay_div){
						item.pay_div_name = code.name
					}
				})
				switch (item.pay_div){
					case 'card':
						item.pay_div_name = '신용카드'
						break;
					case 'phone':
						item.pay_div_name = '휴대폰 소액결제'
						break;
				}
				return item
			})
		}
	}
	,methods: {
		getData: async function(){

			this.is_item = false
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getPointHistory'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
				this.is_excel = false
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toExcel: function(){
			this.excel_data.content = this.item_list
			this.is_excel = true
		}
		, getSearch: function(page){
			this.search.page = page

			this.getData()
		}
		, onCancel: function(item){
			if(confirm('해당 포인트 결제내역을 취소하시겠습니까?')){
				this.postCancel(item)
			}
		}
		, postCancel: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: 'management/postPointCancel'
					, data: item
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
	}
}
</script>